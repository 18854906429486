import { ColumnDef } from '@tanstack/react-table'
import { Asset } from '@/graphql/generated/graphql-request'
import dayjs from 'dayjs'
import { formatNumber } from '@/utils/numberUtils'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { useState } from 'react'

export const tableColumnsUserBalances: ColumnDef<Asset>[] = [
  {
    accessorKey: 'symbol',
    header: () => <div className='text-xs text-left text-white'>Symbol</div>,
    cell: ({ row }) => {
      const symbol = row.original.symbol
      return <div className='border-0 text-left text-xs text-white-dark font-semibold'>{symbol}</div>
    },
    size: 100,
  },
  {
    accessorKey: 'type',
    header: () => <div className='text-xs text-left text-white'>Type</div>,
    cell: ({ row }) => {
      const type = row.original.type
      return <div className='border-0 text-left text-xs text-white-dark font-semibold'>{type}</div>
    },
    size: 100,
  },
  {
    accessorKey: 'available',
    header: () => <div className='text-xs text-left text-white'>Available Balance</div>,
    cell: ({ row, table }) => {
      const balance = table?.options?.meta?.getUserAvailableBalanceForAsset
        ? table?.options?.meta?.getUserAvailableBalanceForAsset(row.original, true)
        : '0'
      return <div className='border-0 text-left text-xs text-success font-semibold'>{formatNumber(balance)}</div>
    },
    size: 100,
  },
  {
    accessorKey: 'locked',
    header: () => <div className='text-xs text-left text-white'>Locked Balance</div>,
    cell: ({ row, table }) => {
      const balance = table?.options?.meta?.getUserAvailableBalanceForAsset
        ? table?.options?.meta?.getUserAvailableBalanceForAsset(row.original, false)
        : '0'
      return <div className='border-0 text-left text-xs text-danger font-semibold'>{formatNumber(balance)}</div>
    },
    size: 100,
  },
  {
    accessorKey: 'borrowed',
    header: () => <div className='text-xs text-left text-white'>Borrowed</div>,
    cell: ({ row, table }) => {
      const balance = table?.options?.meta?.getUserPositionForAsset
        ? table?.options?.meta?.getUserPositionForAsset(row.original, true)
        : '0'
      return <div className='border-0 text-left text-xs text-warning font-semibold'>{formatNumber(balance)}</div>
    },
    size: 100,
  },
  {
    accessorKey: 'supplied',
    header: () => <div className='text-xs text-left text-white'>Supplied</div>,
    cell: ({ row, table }) => {
      const balance = table?.options?.meta?.getUserPositionForAsset
        ? table?.options?.meta?.getUserPositionForAsset(row.original, false)
        : '0'
      return <div className='border-0 text-left text-xs text-white-dark font-semibold'>{formatNumber(balance)}</div>
    },
    size: 100,
  },
]
