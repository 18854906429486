import { ColumnDef } from '@tanstack/react-table'
import { Asset, User, UserRole } from '@/graphql/generated/graphql-request'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { createNotification, NotificationType } from '@/utils/notificationUtils'
import { TableMyAssets } from '@/components/myAssets/TableMyAssets'
import { generateColorsForAssets } from '@/utils/tableUtils'
import { TableUserBalances } from '@/components/administration/TableUserBalances'

export const tableColumnsUserRoles: ColumnDef<User>[] = [
  {
    accessorKey: 'uid',
    header: () => <div className='text-xs text-left text-white'>UID</div>,
    cell: ({ row }) => {
      const uid = row.original.uid
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(uid)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `UID ${uid} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {uid}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'name',
    header: () => <div className='text-xs text-left text-white'>Name</div>,
    cell: ({ row }) => {
      const name = row.original.name ?? ''
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(name)
              .finally(() =>
                createNotification(
                  'Copied to clipboard',
                  `Name ${name} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {name}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'email',
    header: () => <div className='text-xs text-left text-white'>Email</div>,
    cell: ({ row }) => {
      const email = row.original.email
      return (
        <div
          className='border-0 text-left text-xs text-white-dark font-semibold'
          onClick={() => {
            navigator.clipboard
              .writeText(email)
              .finally(() =>
                createNotification(
                  'Copied ',
                  `Email ${email} has been copied to clipboard`,
                  NotificationType.DISPOSABLE,
                ),
              )
          }}
        >
          {email}
        </div>
      )
    },
    size: 300,
  },
  {
    accessorKey: 'roles',
    header: () => <div className='text-xs text-left text-white'>Roles</div>,
    cell: ({ row, table }) => {
      const roles = row.original.roles
      const uid = row.original.uid
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild className={'border-0 bg-card hover:bg-card'}>
            <button
              type='button'
              className='w-[150px] bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
            >
              {roles.length > 0 && roles[roles.length - 1]}
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-full'>
            <DropdownMenuLabel>
              <div className={'w-full ps-8 text-base text-primary'}>Select Role</div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuRadioGroup className={'w-full'}>
              {Object.values(UserRole)?.map((role) => (
                <DropdownMenuRadioItem
                  key={uid}
                  value={role}
                  onClick={() => {
                    if (table?.options?.meta?.updateUserRole) table?.options?.meta?.updateUserRole(role, uid)
                  }}
                >
                  <div className={`p-2 cursor-pointer ${roles[roles.length - 1] === role ? 'text-secondary' : ''}`}>
                    <div className={`flex justify-between items-center gap-4 !w-[300px] font-semibold`}>{role}</div>
                  </div>
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
    size: 150,
  },
  {
    accessorKey: 'addBalances',
    header: () => <div className='text-xs text-left text-white'>Add Balances</div>,
    cell: ({ row, table }) => {
      const uid = row.original.uid
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild className={'border-0 bg-card hover:bg-card'}>
            <button
              type='button'
              className='w-[150px] bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
            >
              Add Balances
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-full'>
            <DropdownMenuLabel>
              <div className={'w-full ps-8 text-base text-primary'}>Add Balances</div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <div className='mx-8 my-4'>
              <select
                id='add-balance-to-user-asset'
                className='relative border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
              >
                {(table?.options?.meta?.allAssets || []).map((asset: Asset) => (
                  <option key={asset?.uid} value={asset?.uid}>
                    {asset?.symbol}
                  </option>
                ))}
              </select>
              <input
                id={'add-balance-to-user-amount'}
                placeholder='Amount'
                className='my-4 border !border-dark rounded-sm shadow-sm appearance-none form-input py-1 peer bg-card placeholder:tracking-widest outline-none focus:ring-0 focus:ring-opacity-0 focus:outline-none no-arrows'
              />
              <button
                type='button'
                className='btn bg-gradient-to-r from-secondary to-primary hover:from-primary hover:to-secondary text-white w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)] '
                onClick={() => {
                  const assetID = (document.getElementById('add-balance-to-user-asset') as any)?.value
                  const asset = (table?.options?.meta?.allAssets || []).find((a) => a.uid === assetID)
                  if (!asset) {
                    return
                  }
                  const amount =
                    Number((document.getElementById('add-balance-to-user-amount') as any)?.value) *
                    Math.pow(10, asset?.decimals)
                  if (table?.options?.meta?.addBalanceToUser)
                    table?.options?.meta?.addBalanceToUser(uid, assetID, amount.toFixed(0))
                }}
              >
                Confirm
              </button>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
    size: 150,
  },
  {
    accessorKey: 'viewBalances',
    header: () => <div className='text-xs text-left text-white'>User Balances</div>,
    cell: ({ row }) => {
      const uid = row.original.uid
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild className={'border-0 bg-card hover:bg-card'}>
            <button
              type='button'
              className='w-[150px] bg-gradient-to-r from-secondary to-primary text-white hover:from-primary hover:to-secondary border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]'
            >
              View Balances
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-full'>
            <DropdownMenuLabel>
              <div className={'w-full flex text-base text-primary justify-start'}>User Balances</div>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <TableUserBalances userUID={uid} />
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
    size: 150,
  },
]
