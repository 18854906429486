import { CreateLiquidityPoolForm } from '@/components/administration/CreateLiquidityPoolForm'
import { TableAdministrationPools } from '@/components/administration/TableAdministrationPools'
import { tableColumnsFunds } from '@/components/administration/TableColumnsPools'
import { Separator } from '@/components/ui/separator'
import { usePools } from '@/hooks/usePools'
import { AddRemoveUserBalance } from '@/components/administration/AddRemoveUserBalance'
import { CreateAssetForm } from '@/components/administration/CreateAssetForm'
import { TableUserRoles } from '@/components/administration/TableUserRoles'
import { SupplyPanel } from '@/components/administration/SupplyPanel'
import { CollateralizePanel } from '@/components/administration/CollateralizePanel'
import { WithdrawPanel } from '@/components/administration/WithdrawPanel'
import { RepayPanel } from '@/components/administration/RepayPanel'
import { CandlesPanel } from '@/components/administration/candlesPanel'
import { OrderBots } from '@/components/administration/OrderBots'
import { TraderBots } from '@/components/administration/TraderBots'

export const Administration = () => {
  const { allPools } = usePools()

  return (
    <div className={'flex flex-col w-full text-white-dark overflow-auto h-screen p-4 pb-14'}>
      <div className='w-full flex flex-col gap-2 overflow-scroll'>
        <div className='w-full '>
          <div className='w-full text-xl font-semibold justify-start mt-2 text-primary'>Users</div>
          <Separator className='mb-2' />
          <div className='flex flex-row'>
            <AddRemoveUserBalance />
          </div>
          <div className='flex flex-row mt-2'>
            <TableUserRoles />
          </div>
        </div>

        <div className='w-full '>
          <div className='w-full text-xl font-semibold justify-start mt-2 text-primary'>Bots</div>
          <Separator className='mb-2' />
          <div className='flex flex-row mt-2'>
            <OrderBots />
          </div>
          <div className='flex flex-row mt-2'>
            <TraderBots />
          </div>
        </div>

        <div className='w-full'>
          <div className='w-full text-xl font-semibold justify-start mt-2 text-primary'>Assets</div>
          <Separator className='mb-2' />
          <div className='w-full flex flex-row gap-2'>
            <CreateAssetForm />
          </div>
        </div>

        <div className='w-full '>
          <div className='w-full text-xl font-semibold justify-start mt-2 text-primary'>
            Asset Management & Operations
          </div>
          <Separator className='mb-2' />
          <div className=' flex w-full justify-start gap-2'>
            <SupplyPanel />
            <CollateralizePanel />
            <WithdrawPanel />
            <RepayPanel />
          </div>
        </div>

        <div className='w-full'>
          <div className='w-full mt-2 text-xl font-semibold justify-start text-primary'>Pools</div>
          <Separator className='mb-2' />
          <div className='w-full flex flex-col gap-2 justify-between'>
            <CreateLiquidityPoolForm />
            <div className='w-full panel dark:bg-card'>
              <TableAdministrationPools columns={tableColumnsFunds} data={allPools} />
            </div>
          </div>
        </div>

        <div className='w-full'>
          <CandlesPanel />
        </div>
      </div>
    </div>
  )
}
